:root {
  /* Light Mode */
  --primary-light: #1E90FF;
  --secondary-light: #FFFFFF;
  --accent-light: #E0E0E0;
  --text-color-light: #333333;
  --background-light: #FFFFFF;

  /* Dark Mode */
  --primary-dark: #1C1C1C;
  --secondary-dark: #2E3A4D;
  --accent-dark: #FFFFFF;
  --text-color-dark: #FFFFFF;
  --background-dark: #1C1C1C;

  /* Common */
  --transition-speed: 0.3s;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  transition: background-color var(--transition-speed), color var(--transition-speed);
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body.light-mode {
  background-color: var(--background-light);
  color: var(--text-color-light);
}

body.dark-mode {
  background-color: var(--background-dark);
  color: var(--text-color-dark);
}

a {
  color: var(--primary-light);
  text-decoration: none;
  transition: color var(--transition-speed), transform 0.3s ease;
}

body.light-mode a {
  color: var(--primary-light);
}

body.dark-mode a {
  color: var(--accent-dark);
}

a:hover {
  color: var(--secondary-light);
  transform: scale(1.05);  /* Hover effect */
}

body.dark-mode a:hover {
  color: var(--secondary-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}