.councilSection {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.councilGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.councilMember {
  background-color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-md);
  box-shadow: var(--chakra-shadows-md);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.councilMember:hover {
  transform: translateY(-5px);
}

.memberContent {
  padding: 1.5rem;
}

.memberName {
  font-size: var(--chakra-fontSizes-xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin-bottom: 0.5rem;
}

.memberTitle {
  font-size: var(--chakra-fontSizes-md);
  color: var(--chakra-colors-gray-600);
  margin-bottom: 1rem;
}

.memberDescription {
  font-size: var(--chakra-fontSizes-sm);
}

@media (max-width: 768px) {
  .councilGrid {
    grid-template-columns: 1fr;
  }
}
