.aboutSection {
  padding: 6rem 0;
  /* Αφαίρεση του background color */
}

.aboutSection h2 {
  margin-bottom: 3rem;
  color: var(--chakra-colors-blue-700);
  font-weight: bold;
}

.aboutSection h3 {
  margin-bottom: 1.5rem;
  color: var(--chakra-colors-blue-600);
}

.aboutSection p {
  margin-bottom: 2rem;
  line-height: 1.8;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .aboutSection {
    padding: 4rem 0;
  }
}

.aboutBox {
  background-color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-lg);
  box-shadow: var(--chakra-shadows-lg);
  padding: 2.5rem;
  transition: all 0.3s ease-in-out;
}

.aboutBox:hover {
  transform: translateY(-5px);
  box-shadow: var(--chakra-shadows-xl);
}

.aboutGrid {
  gap: 2rem;
}