/* frontend/src/components/ArticlesSection/ArticlesSection.module.css */

.articlesSection {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.card {
  background-color: var(--chakra-colors-white);
  border-radius: var(--chakra-radii-md);
  box-shadow: var(--chakra-shadows-md);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
}

.cardContent {
  padding: 1.5rem;
}

.title {
  font-size: var(--chakra-fontSizes-xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin-bottom: 0.5rem;
}

.excerpt {
  font-size: var(--chakra-fontSizes-md);
  color: var(--chakra-colors-gray-600);
  margin-bottom: 1rem;
  text-align: justify;
}

.readMore {
  color: var(--chakra-colors-blue-500);
  font-weight: var(--chakra-fontWeights-semibold);
  text-decoration: none;
}

.readMore:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
